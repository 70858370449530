import "./Home.css";
import { Component } from "react";
import MediaQuery from "react-responsive";
import HomeCarousel from "./HomeCarousel";
import EssenceService from "../Utils/essence-services";
import EssenceServiceMobile from "../Utils/essence-services-mobile";
import ReviewsSlider from "../Components/ReviewsSlider";
import { Link } from "react-router-dom";

class Home extends Component {
  
  componentDidMount() {
    // setTimeout(function () {
    //   alert(
    //     "Due to injury the clinic is temporarily closed until further notice. Please pop us an email to be contacted when the clinic reopens. Thanks for your understanding, Kirsten."
    //   );
    // }, 1000);
  }

  render() {
    return (
      <div className="home">
        <MediaQuery minWidth={1224}>
          <div className="home-desktop">
            <div className="header-carousel">
              <HomeCarousel></HomeCarousel>
            </div>
            <div className="contentBody">
              <div className="home-services">
                <h2>Our Services</h2>
                <EssenceService />
                <div className="video-container">
                  <iframe
                    title="Essence Massage &amp; Bodyworks"
                    width="1000"
                    height="480"
                    src="https://www.youtube.com/embed/a9ul4YPLwak?feature=oembed"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen=""
                  ></iframe>
                </div>
              </div>
            </div>
            <ReviewsSlider></ReviewsSlider>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <MediaQuery minWidth={414}>
            <div className="home-tablet-body">
              <Link
                className="home-links"
                to={{ pathname: "/modal/book/0", state: { modal: true } }}
              >
                <div className="homeLinkContainer">
                  <p className="essence-orange">
                    <strong>BOOK NOW</strong>
                  </p>
                </div>
              </Link>
              <Link className="home-links" to="/vouchers">
                <div className="homeLinkContainer">
                  <p className="essence-orange">
                    <strong>GIFT VOUCHERS</strong>
                  </p>
                </div>
              </Link>
              <h2>Our Services</h2>
              <EssenceService />
              <div className="video-container-tablet">
                <iframe
                  title="Essence Massage &amp; Bodyworks"
                  width="1000"
                  height="480"
                  src="https://www.youtube.com/embed/a9ul4YPLwak?feature=oembed"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
              <ReviewsSlider></ReviewsSlider>
            </div>
          </MediaQuery>
        </MediaQuery>
        <MediaQuery maxWidth={414}>
          <div className="home-mobile-body">
            <Link
              className="home-links"
              to={{ pathname: "/modal/book/0", state: { modal: true } }}
            >
              <div className="homeLinkContainer">
                <p className="essence-orange">
                  <strong>BOOK NOW</strong>
                </p>
              </div>
            </Link>
            <Link className="home-links" to="/vouchers">
              <div className="homeLinkContainer">
                <p className="essence-orange">
                  <strong>GIFT VOUCHERS</strong>
                </p>
              </div>
            </Link>
            <div className="contentBody">
              <div className="home-services-mobile">
                <h2>Our Services</h2>
                <EssenceServiceMobile />
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default Home;
